export default () => ({
    visibleNav: false,
    hiddenNav: true,
    logo: null,
    nav: null,
    navContainer: null,
    search: null,
    searchBackdrop: null,
    visibleSearch: false,
    hiddenSearch: true,
    enterTimer: [],
    leaveTimer: [],

    init() {
        this.logo = this.$root.querySelector('#logo');
        this.nav = this.$root.querySelector('.nav-container nav');
        this.navContainer = this.$root.querySelector('.nav-container');
        this.search = this.$root.querySelector('.nav-search');
        this.searchBackdrop = document.createElement('div');
        this.scrollHandler();
        this.searchBackdrop.classList.add(
            'search-backdrop',
            'hidden',
            'z-30',
            'w-full',
            'fixed',
            'inset-0',
            'bg-gray-900',
            'opacity-25'
        );
        document.body.appendChild(this.searchBackdrop);
        window.addEventListener('scroll', () => this.scrollHandler(), { passive: true });

        if (!window.isTouchDevice()) {
            this.initHover();
        }
    },

    initHover() {
        this.$root.querySelectorAll('nav > ul > li > a.toggle-sub').forEach((elem, index) => {
            const listItem = elem.closest('li');
            listItem.addEventListener('mouseenter', event => {
                const sub = event.target.querySelector('.sub');
                clearTimeout(this.leaveTimer[index]);
                this.enterTimer[index] = setTimeout(() => {
                    this.$root.querySelectorAll('sub').forEach(subElem => {
                        if (!subElem.isSameNode(sub)) {
                            subElem.classList.add('hidden');
                        }
                    });
                    sub.classList.remove('hidden');
                }, 300);
            });
            listItem.addEventListener('mouseleave', event => {
                const sub = event.target.querySelector('.sub');
                clearTimeout(this.enterTimer[index]);
                this.leaveTimer[index] = setTimeout(() => sub.classList.add('hidden'), 300);
            });
        });
    },

    scrollHandler() {
        if (window.scrollY > 60) {
            this.navContainer.classList.remove('h-20', 'lg:h-27');
            this.navContainer.classList.add('h-14', 'lg:h-20');
            this.logo.classList.remove('h-12', 'lg:h-15');
            this.logo.classList.add('h-9', 'lg:h-12');
            this.nav.classList.remove('top-20');
            this.nav.classList.add('top-14');
        } else {
            this.navContainer.classList.remove('h-14', 'lg:h-20');
            this.navContainer.classList.add('h-20', 'lg:h-27');
            this.logo.classList.remove('h-9', 'lg:h-12');
            this.logo.classList.add('h-12', 'lg:h-15');
            this.nav.classList.remove('top-14');
            this.nav.classList.add('top-20');
        }
    },

    toggleNav() {
        this.visibleNav = !this.visibleNav;
        this.hiddenNav = !this.hiddenNav;
    },

    hideNav() {
        this.visibleNav = false;
        this.hiddenNav = true;
    },

    toggleSearch(event) {
        event.target?.blur();
        if (this.search) {
            this.visibleSearch = !this.visibleSearch;
            this.hiddenSearch = !this.hiddenSearch;
            this.search.classList.toggle('hidden');
            document.body.classList.toggle('overflow-hidden');
            document.body.querySelector('.search-backdrop').classList.toggle('hidden');
            if (!this.search.classList.contains('hidden')) {
                this.search.querySelector('input').focus({ preventScroll: true, focusVisible: true });
            }
        }
    },

    clearSearch(event) {
        if (this.search) {
            const oldValue = this.search.querySelector('input').value;
            this.search.querySelector('input').value = '';
            if (oldValue.length) {
                this.search.querySelector('input').dispatchEvent(new Event('input'));
            }
            this.search.querySelector('input').focus({ preventScroll: true, focusVisible: true });
        }
    },

    toggleSub(event) {
        const link = event.target.closest('a');

        if (window.isTouchDevice()) {
            event.preventDefault();
            link.closest('ul')
                .querySelectorAll(':scope > li > a.toggle-sub')
                .forEach(element => {
                    if (
                        !element.nextElementSibling.classList.contains('hidden') &&
                        !element.nextElementSibling.isEqualNode(link.nextElementSibling)
                    ) {
                        element.nextElementSibling.classList.add('hidden');
                    }
                });
            link.nextElementSibling.classList.toggle('hidden');
        } else {
            window.location.href = link.getAttribute('href');
        }
    },

    showContinent(event) {
        if (!window.isTouchDevice()) {
            this.$root.querySelector('.continent-0 div').style.display = 'none';
        }
    },

    hideContinent(event) {
        if (!window.isTouchDevice()) {
            this.$root.querySelector('.continent-0 div').style.display = 'block';
        }
    },
});
