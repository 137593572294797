export default () => ({
    init() {
        this.set();
        Livewire.on('form:update', () => this.set());
    },

    set() {
        if (!this.$root.value.length) {
            this.$root.type = 'text';
        }

        this.$root.addEventListener('focus', event => (event.target.type = 'date'));
        this.$root.addEventListener('blur', event => (event.target.type = event.target.value.length ? 'date' : 'text'));
    },
});
